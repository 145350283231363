import React from "react";
import "../styles/Calendaire.css";

export default function Calendaire() {
     return (
          <div style="{{" padding:="" "0%="" 1%",="" }}="">
               <p style="{{" textTransform:="" "uppercase",="" fontSize:="" "17px",="" fontWeight:="" "500",="" letterSpacing:="" "11px",="" margin:="" "o%",="" }}="">
                    A venir
               </p>

               <ul style="{{" margin:="" "0%",="" listStyle:="" "none",="" padding:="" }}="">
                    <li>
                         2025 - Nouveau projet pour The Interstring Project (DE).
                         Nouvelle pièce pour 4 guitares électriques.
                    </li>
                    <li>
                         12/04/2025 - Infused 3'600'000ms, nouveau projet multimédia, commandé par la Biennale de musique de Zagreg.
                         multimédia, commandé par la Biennale de musique de Zagreg,
                         créé par l'ensemble Elision dans le cadre de ZMB25 (CH).
                    </li>
                    <li>
                         tbc/01/2025 - Infused 3'600'000ms (version réduite),
                         nouveau projet multimédia, dans le cadre du programme ICST pre-PhD
                         au ZHdK (CH), créé par Joshua Hyde + Francisco
                         Uberto.
                    </li>
                    <li>
                         24/10/24 All my neurons have clits, Flock Lab,
                         Ambassade de l'Argentine à Paris, Paris (FR)
                    </li>
                    <li>
                         17/10/24 Tous mes neurones ont des lolos, Flock Lab, CRD de
                         Pantin, Pantin (FR)
                    </li>
                    <li>
                         15/10/24 LIFE electronics, Émilie Girard-Charest,
                         marché de noël, le Phoenix à Valenciennes (FR)
                    </li>

                    {/* <li>
                         28/06/24 1Up - set solo, Galerie du Haut Pavé, Paris
                         (FR)
                    </li> */}
                    {/* <li>
                         15/05/24 K.A.M.I.K.A.Z.E. B-L duo au Kater Blau à
                         Berlin dans le cadre de classicalNEXT '24(DE)
                    </li>
                    <li>
                         27/04/24 K.A.M.I.K.A.Z.E. B-L duo at The music parlour
                         (hôtel de ville) Singapour (SG)
                    </li> */}
                    {/* <li>
                         15/03/24 Paradise (or how I'll get there from a door  au troisième étage), salle d'orchestre à la HEAR, Paris.
                         le troisième étage), salle d'orchestre à la HEAR,
                         Strasbourg (FR)
                    </li>
                    <li>
                         02/02/24 - LIFE electronics. mastering au studio "la
                         cave à son" (+ d'infos bientôt), Paris (FR)
                    </li>
                    <li>
                         11/01/24 - All by myself - solo set performance -
                         Maison de l'Argentine - Paris (FR)
                    </li>

                    <li>
                         25/11/23 - K.A.M.I.K.A.Z.E - B-L duo - hcfm// festival
                         (ROYAUME-UNI)
                    </li>
                    <li>
                         11/11/23 - P.A.R.A.D.I.S.E. - Li Li-Chin - C-Lab -
                         Taipei (TW)
                    </li>
                    <li>
                         26/10/23 - Elettrificare il Rosa - Trio Métallique -
                         Cité Internationale des Arts, Paris (FR)
                    </li> */}
               </ul>
          </div>
     ) ;
}
