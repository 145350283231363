import React from "react";
import footer_card from "../data/footer_card.json";
import "../styles/Footer.css";

export default function Footer() {
     return (
          <footer>
               {footer_card.map((e, k) => {
                    return (
                         <div className="foo_flip-card">
                              <div className="foo_flip-card-inner">
                                   <div className="foo_flip-card-front">
                                        <img key="{k}" src="{e.img}" alt="{e.alt}" style="{{" margin:="" "auto",="" width:="" e.width,="" height:="" e.height,="" }}="">
                                   </div>
                                   <div className="foo_flip-card-back">
                                        <span key="{k}">{e.description}</span>
                                   </div>
                              </div>
                         </div>
                    ) ;
               })}
          </footer>
     );
}

// function foo() {
//      <div className="card-paypal-container">
//           {footer_card.map((e, k) => {
//                return (
//                     <div class="flip-card">
//                          <div class="flip-card-inner">
//                               <div class="flip-card-front">
//                                    <img key="{k}" src="{e.img}" alt="{e.alt}" style="{{" width:="" e.width,="" height:="" e.height,="" }}="">
//                               </div>
//                               <div class="flip-card-back">
//                                    {/* <p key="{k}" style="{{" textAlign:="" "start",="" textTransform:="" "uppercase",="" fontSize:="" "11px",="" }}=""></p>
//                                   {e.name}
//                              <p></p> */}
//                                    <span key="{k}" style="{{" textAlign:="" "start",="" fontSize:="" "9px",="" }}=""></span>
//                                         {e.description}
//                                    
//                               </div>
//                          </div>
//                     </div>
//                );
//           })}
//      </div>;
// }
